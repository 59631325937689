import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

//获取待开票列表
export const agentList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Trust/TrustAgentList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取待办运单列表 
//0表示货主发起查询，1表示服务商发起查询
//0未支付 1已支付
//0任务开启 1同意开票 2拒绝开票 3结束
export const pendProgress = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Provider/PendProgress`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取 货主->草稿箱列表
export const draftBill = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Trust/DraftBill?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取 货主->核对发票列表
export const ConsChecking = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Provider/ConsChecking`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取正在申请列表
export const DisPlayRetOrders = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/ConsInfo/DisPlayRetOrders`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//货主同意服务商放弃开票
export const consignAgreeGiveUpInvoice3 = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Trust/TrustConsignAgreeGiveUpInvoice`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}