<template>
  <div class="supplierPendingDetailPage">
    <el-tabs v-model="currentStep" type="card" @tab-click="stepChange">
      <!-- 货主 -->
      <el-tab-pane label="正在申请" name="1"></el-tab-pane>
      <el-tab-pane label="确认付款" name="3"></el-tab-pane>
      <el-tab-pane label="核对发票" name="4"></el-tab-pane>
    </el-tabs>
    <Wait v-if="currentStep == 1"></Wait>
    <ConfirmPayment v-if="currentStep == 3"></ConfirmPayment>
    <Agree v-if="currentStep == 4"></Agree>
  </div>
</template>

<script>
import Agree from './cmpt/agree'
import ConfirmPayment from './cmpt/confirmPayment'
import Wait from './cmpt/wait'
export default {
  data() {
    return {
      currentStep: '1', //当前处于第几步
      enterpriseFullName: '', //企业名称
      id: '', //待办列表id
    }
  },
  methods: {
    stepChange(e) {
      this.currentStep = String(e.name)
    },
  },
  created() {
    this.enterpriseFullName = this.$route.query.enterpriseFullName
    this.id = this.$route.query.id
    this.currentStep = this.$route.query.activeCmpt ? this.$route.query.activeCmpt : '1'
  },
  components: {
    Agree,
    ConfirmPayment,
    Wait,
  }
}
</script>

<style lang="scss" scoped>
</style>